import React from "react";
import "../css/footer.css";

function Footer() {

  return (
    <footer className="Footer">
      <div className="cstm-container">
        <div className="footer">
            <div className="footer-content">
                <div className="link">
                    <a href="algemene-voorwaarden" target="_blank" rel="noopener noreferrer" alt="Algemene voorwaarden">Algemene voorwaarden</a>
                </div>
                <div className="link">
                    <a href="https://delpdesign.com/"  target="_blank" rel="noopener noreferrer" alt="Delp Design - webdesign & development bureau">created by delpdesign</a>
                </div>
            </div>            
        </div>
      </div>
    </footer>
  );
}

export default Footer;
