import React from "react";

function Login() {
  return (
    <div className="login">
        Page for Login
    </div>
  );
}

export default Login;
