import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import AdminPortal from "./admin/adminportal";
import Login from "./admin/login";
import Conditions from "./components/conditions";
import NotFound from "./components/404";
import reportWebVitals from "./reportWebVitals";
import "./css/index.css"


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },

  {
    path: "/adminportal",
    element: <AdminPortal />,
  },
  {
    path: "/login-dev",
    element: <Login />,
  },
  
  {
    path: "/algemene-voorwaarden",
    element: <Conditions />,
  },
  {
    path: "*",
    element: <NotFound />,
  },

]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);



reportWebVitals();
