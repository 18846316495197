import React from "react";
import "../css/contact.css";
import insta from "../img/instagram-logo.png";
import facebook from "../img/facebook.png";

function Contact() {
  return (
    <section className="Contact" id="contact">
      <div className="cstm-container">
        <header className="subtitle subtitle-dark">
          <h2>Contact & Socials</h2>
        </header>
        <address className="contact">
          <div className="contact-item">
            <ul className="general">
              <li>
                <strong>Telefoon:</strong>
                <p>
                  <a href="tel:+32 0492 55 55 55" target="_blank" rel="noopener noreferrer">+32 471 54 90 42</a>
                </p>
              </li>
              <li>
                <strong>Email:</strong>
                <p>
                  <a href="mailto:info@stupu.be" target="_blank" rel="noopener noreferrer">info@stupu.be</a>
                </p>
              </li>
            </ul>
          </div>
          <div className="contact-item">
            <ul className="socials">
              <li>
                <a href="https://www.instagram.com/stupube/?igsh=MXhuZXI1NWtrZ3Npag%3D%3D" target="_blank" rel="noopener noreferrer">
                  <img src={insta} alt="Facebook - StuPu" />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/profile.php?id=61566001246716&locale=nl_NL" target="_blank" rel="noopener noreferrer">
                  <img src={facebook} alt="Instagram - StuPu" />
                </a>
              </li>
            </ul>
          </div>
        </address>
      </div>
    </section>
  );
}

export default Contact;
