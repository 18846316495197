import React from "react";
import "../css/how.css";
import dateIcon from "../img/date.png";
import matchIcon from "../img/match.png";
import confirmIcon from "../img/confirm.png";

function How() {
  return (
    <section className="How" id="hoewerktstupu">
      <div className="cstm-container">
        <header className="subtitle">
          <h2>Hoe werkt StuPu?</h2>
        </header>
        <article className="how">
          <div className="list-of-items">
            <ul>
              <li>
                <article className="list-item">
                <img src={dateIcon} alt="Beschikbaarheid" />
                    <h3>Beschikbaarheid aangeven</h3>
                    <p>Geef aan wanneer, waar en hoe je bijles wilt geven.</p>
                </article>
              </li>
              <li>

                <article className="list-item">
                  <img src={matchIcon} alt="lesgever" />
                    <h3>Kiesbare lesgever</h3>
                    <p>
                      Word gematcht op basis van beschikbaarheid en voorkeuren
                      van leerlingen.
                    </p>
                </article>
              </li>
              <li>
                <article className="list-item">
                  <img src={confirmIcon} alt="bijles" />
                    <h3>Geef bijles en groei!</h3>
                    <p>
                      Bevestig je lessen, ontvang digitale contracten en ga van
                      start!
                    </p>
                </article>
              </li>
            </ul>
          </div>
        </article>
      </div>
    </section>
  );
}

export default How;
