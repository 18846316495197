import React, { useState } from "react";
import "../css/nav.css";

function Nav() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScrollTo = (event, id) => {
    event.preventDefault(); // Prevent default anchor behavior
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setIsOpen(false);
    }
  };

  return (
    <nav className="navbar">
      <div className={`navbar-mobile ${isOpen ? "active" : ""}`}>
        <ul>
          <li>
            <a onClick={(e) => handleScrollTo(e, "hoewerktstupu")} href="#hoewerktstupu">Hoe werkt StuPu?</a>
          </li>
          <li>
            <a onClick={(e) => handleScrollTo(e, "hoe")} href="#hoe">Hoe meld ik mij aan?</a>
          </li>
          <li>
            <a onClick={(e) => handleScrollTo(e, "formulier")} href="#formulier">Schrijf je in!</a>
          </li>
          <li>
            <a onClick={(e) => handleScrollTo(e, "contact")} href="#contact">Contact & Socials</a>
          </li>
        </ul>
      </div>
      <div className="cstm-container">
        <div className="navbar-menu">
          <ul>
            <li>
              <a onClick={(e) => handleScrollTo(e, "hoewerktstupu")} href="#hoewerktstupu">Hoe werkt StuPu?</a>
            </li>
            <li>
              <a onClick={(e) => handleScrollTo(e, "hoe")} href="#hoe">Hoe meld ik mij aan?</a>
            </li>
            <li>
              <a onClick={(e) => handleScrollTo(e, "formulier")} href="#formulier">Schrijf je in!</a>
            </li>
            <li>
              <a onClick={(e) => handleScrollTo(e, "contact")} href="#contact">Contact & Socials</a>
            </li>
          </ul>
        </div>
        <div className="navbar-toggle">
          <div className="navbar-ham" onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
