import React from "react";
import "../css/cta.css";

function Cta() {
  const handleScrollToContact = () => {
    const contactSection = document.getElementById("formulier");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section className="Cta">
      <div className="cstm-container">
        <div className="cta">
            <div className="cta-content">
                <h2>
                    Registreer vandaag!
                </h2>
              <button onClick={handleScrollToContact} >Meld u aan!</button>
            </div>            
        </div>
      </div>
    </section>
  );
}

export default Cta;
