import React from "react";
import "../css/header.css";
import logo from "../img/logo.webp";

function Header() {
  return (
    <div className="Header">
      <div className="cstm-container">
        <header className="lp-header">
          <div className="bg-logo">
            <img src={logo} alt="StuPu Bijlesplatform" />
          </div>
          <div className="head-title">
            <h1>
              Welkom bij StuPu – het bijlesplatform door studenten lerarenopleiding.
            </h1>
          </div>
          <div className="head-qoute">
            <p>
              Met Stupu krijg jij als student de kans om je te ontwikkelen als
              leerkracht terwijl je aan je eigen toekomst bouwt. Tegelijkertijd
              help je leerlingen in het middelbaar en lager onderwijs met
              betaalbare, kwalitatieve bijlessen.
            </p>
          </div>
          <div className="head-cta">
              <h2>Klaar om “Bijlesgever” te worden?</h2>   
          </div>
          
        </header>
      </div>
    </div>
  );
}

export default Header;
