import React from "react";
import "../css/conditions.css";

function Conditions() {
  return (
    <div className="conditions">
      <div className="cstm-container">
        <h1>GDPR-verklaring voor het verzamelen van persoonsgegevens</h1>
        <h2>Inleiding</h2>
        <p>
          Bij StuPu hechten we veel waarde aan de bescherming van uw
          persoonsgegevens. Deze verklaring legt uit hoe wij uw gegevens
          verzamelen, gebruiken en beschermen in overeenstemming met de Algemene
          Verordening Gegevensbescherming (GDPR).
        </p>
        <h2>Doel van gegevensverwerking</h2>
        <p>Wij verzamelen uw persoonsgegevens voor de volgende doeleinden:</p>
        <ul>
          <li>Om u in te schrijven als bijlesgever via ons platform.</li>
          <li>Om u toegang te geven tot uw account en studentencontracten.</li>
          <li>
            Om contact met u op te nemen over belangrijke informatie met
            betrekking tot uw bijlesactiviteiten.
          </li>
          <li>Voor administratieve en organisatorische doeleinden.</li>
        </ul>
        <h2>Verzamelde persoonsgegevens</h2>
        <p>De persoonsgegevens die wij van u verzamelen kunnen omvatten:</p>
        <ul>
          <li>Voornaam en achternaam</li>
          <li>Geboorteplaats & datum </li>
          <li>E-mailadres </li>
          <li>Telefoonnummer </li>
          <li>Adres </li>
          <li>Opleidingsgegevens </li>
        </ul>
        <h2>Rechtsgrond voor verwerking</h2>
        <p>
          Wij verwerken uw persoonsgegevens op basis van uw toestemming, die u
          kunt intrekken wanneer u maar wilt, en op basis van de uitvoering van
          de overeenkomst die wij met u aangaan.
        </p>
        <h2>Bewaartermijn</h2>
        <p>
          Uw persoonsgegevens worden niet langer bewaard dan noodzakelijk is
          voor de doeleinden waarvoor ze zijn verzameld, met inachtneming van
          wettelijke verplichtingen.
        </p>
        <h2>Beveiliging van gegevens</h2>
        <p>
          Wij nemen passende technische en organisatorische maatregelen om uw
          persoonsgegevens te beschermen tegen verlies, diefstal of
          ongeoorloofde toegang.
        </p>
        <h2>Rechten van betrokkenen</h2>
        <p></p>
        <ul>
          <li>Inzage te vragen in uw persoonsgegevens.</li>
          <li>
            Correctie of verwijdering van uw persoonsgegevens te verzoeken.
          </li>
          <li>Beperking van de verwerking te vragen.</li>
          <li>Uw gegevens over te dragen.</li>
          <li>Tegen de verwerking van uw gegevens bezwaar te maken.</li>
        </ul>

        <h2>Contact</h2>
        <p>
          Als u vragen heeft over deze GDPR-verklaring of uw persoonsgegevens,
          kunt u contact met ons opnemen via:
        </p>
        <ul>
          <li>E-mailadres: noud@stupu.be</li>
          <li>Telefoonnummer: +32 471 54 90 42</li>
        </ul>
        <h2>Wijzigingen in deze verklaring</h2>
        <p>
          Wij behouden ons het recht voor om deze verklaring te wijzigen. Wij
          adviseren u om deze verklaring regelmatig te raadplegen.
        </p>
      </div>
    </div>
  );
}

export default Conditions;
