import Nav from "./components/navigation";
import Header from "./components/header";
import How from "./components/how";
import Cta from "./components/cta";
import Steps from "./components/steps";
import Form from "./components/form";
import Contact from "./components/contact";
import Footer from "./components/footer";



function App() {
  return (
    <>      
      <Nav /> 
      <Header />
      <How />
      <Cta />
      <Steps />
      <Form />
      <Contact />
      <Footer />
    </>

  );
}

export default App;
