import React from "react";

function AdminPortal() {
  return (
    <div className="adminportal">
        Page for adminportal
    </div>
  );
}

export default AdminPortal;
